import clsx from "clsx"
import PropTypes from "prop-types"
import React from "react"

import MuiButton from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"
import makeStyles from "@material-ui/styles/makeStyles"

const useStyles = makeStyles(theme => ({
  root: {
    "& .app-btn-grey": {
      color: theme.color.grey,
      background: theme.color.light,
      fontSize: "0.8rem",
      borderRadius: "10px",
      padding: "10px 12px",
      fontFamily: theme.font.galanoSemi,
    },
    "& .navbar": {
      boxShadow: "none",
      fontSize: "0.8rem",
      padding: "10px 15px",
      lineHeight: 1.5,
      backgroundColor: theme.color.orange,
      border: `2px solid ${theme.color.orange}`,
      fontFamily: theme.font.galanoSemi,
      color: theme.color.white,
      transition: "all 0.25s ease",
      cursor: "pointer",
      borderRadius: "10px",
      "&.Mui-disabled": {
        opacity: "0.5",
      },
      "&:hover": {
        color: theme.color.black,
        background: theme.color.white,
      },
      "& .MuiTouchRipple-ripple": {
        color: theme.color.orange,
      },
    },
    "& .upload": {
      border: `2px solid ${theme.color.orange}`,
      fontSize: "1rem",
      color: theme.color.white,
      padding: "4px",
      borderRadius: "10px",
      background: theme.color.orange,
      fontFamily: theme.font.galanoSemi,
      transition: "all 0.25s ease",
      boxShadow: "none",
      display: "block",
      margin: "30px auto 0 auto",
      width: "100%",
      "&.Mui-disabled": {
        opacity: "0.5",
      },
      "&:hover": {
        color: theme.color.black,
        background: theme.color.white,
      },
      "& .MuiTouchRipple-ripple": {
        color: theme.color.orange,
      },
    },
    [theme.breakpoint.tablet]: {
      "& .upload": {
        margin: "0",
        width: "150px",
        "&.margin-top": {
          marginTop: "20px",
        },
        "&.payment": {
          minWidth: "250px",
        },
      },
    },
    [theme.breakpoint.desktop]: {
      "& .upload": {},
    },
  },
  wrapper: {
    position: "relative",
  },
  fullWidth: {
    width: "100%",
  },
  buttonProgress: {
    color: theme.color.dark,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  loading: {
    opacity: "0",
  },
}))

const Button = ({
  children,
  rootClassName,
  wrapperClassName,
  loading,
  ...props
}) => {
  const classes = useStyles()
  return (
    <div className={clsx(classes.root, rootClassName)}>
      <div
        className={clsx(
          classes.wrapper,
          {
            [classes.fullWidth]: props.fullWidth,
          },
          wrapperClassName
        )}
      >
        <MuiButton {...props}>
          <span className={loading ? classes.loading : null}>{children}</span>
          {loading && (
            <CircularProgress size={20} className={classes.buttonProgress} />
          )}
        </MuiButton>
      </div>
    </div>
  )
}

Button.propTypes = {
  children: PropTypes.node,
  fullWidth: PropTypes.bool,
  rootClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
  loading: PropTypes.bool,
}

Button.defaultProps = {
  fullWidth: false,
  loading: false,
}

export default Button
