import gql from "graphql-tag"

export const CUSTOMER_FETCH = gql`
  query getCustomerDetails($customerAccessToken: String!) {
    customer(customerAccessToken: $customerAccessToken) {
      displayName
      firstName
      lastName
      email
    }
  }
`
