import { StaticQuery, graphql } from "gatsby"
import React from "react"

import Typography from "@material-ui/core/Typography"

const Copyright = () => (
  <StaticQuery
    query={query}
    render={({
      site: {
        siteMetadata: { copyright },
      },
    }) => (
      <Typography variant="body2" color="textSecondary" align="center">
        {copyright}
      </Typography>
    )}
  />
)

export default Copyright

const query = graphql`
  query Copyright {
    site {
      siteMetadata {
        copyright
      }
    }
  }
`
