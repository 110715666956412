import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"

import { useTheme } from "@material-ui/core/styles"

import Snackbar from "./Snackbar"

const Notification = ({ notification }) => {
  const [open, setOpen] = useState(false)
  const theme = useTheme()

  useEffect(() => {
    setOpen(false)
    setTimeout(() => {
      setOpen(true)
    }, theme.transitions.duration.leavingScreen)
  }, [notification, theme.transitions.duration.leavingScreen])

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }
    setOpen(false)
  }

  return (
    <>
      {notification ? (
        <Snackbar
          message={notification.message}
          onClose={handleClose}
          open={open}
          variant={notification.variant}
        />
      ) : null}
    </>
  )
}

Notification.propTypes = {
  notification: PropTypes.object,
}

export default connect(state => ({
  notification: state.notifications.notification,
}))(Notification)
