import PropTypes from "prop-types"
import React from "react"

import Switch from "@material-ui/core/Switch"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import styled from "styled-components"

const SwitchStyles = styled(FormControlLabel)`
  margin-left: 0;
  display: flex;
  justify-content: space-between;
  .Mui-checked {
    color: ${props => props.theme.color.orange};
  }
  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: ${props => props.theme.color.orange};
  }
  .MuiSwitch-track {
    height: 26px;
    border-radius: 15px;
    position: relative;
    bottom: 3px;
  }
  .MuiSwitch-root {
    width: 70px;
    height: 50px;
  }
  .MuiSwitch-thumb {
    width: 25px;
    height: 25px;
  }
  .MuiTypography-root {
    font-size: 0.9rem;
    font-family: ${props => props.theme.font.galanoSemi};
    color: ${props => props.theme.color.grey};
  }
`

const ToggleSwitch = ({ field, label, form, ...props }) => {
  const error = form.errors[field.name] && form.touched[field.name]

  const handleBlur = () => {
    form.setFieldTouched(field.name, true)
  }

  const handleChange = name => event => {
    form.setFieldValue(name, event.target.checked)
  }

  return (
    <SwitchStyles
      error={error}
      control={
        <>
          {/* <p>{field.value ? "Yes" : "No"}</p> */}
          <Switch
            onBlur={handleBlur}
            onChange={handleChange(field.name)}
            checked={field.value}
            value={field.name}
            variant="outlined"
            {...props}
          />
        </>
      }
      label={label}
      labelPlacement="start"
    />
  )
}

ToggleSwitch.propTypes = {
  ...Switch.propTypes,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  label: PropTypes.string,
}

export default ToggleSwitch
