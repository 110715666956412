/**
 * @flow
 */

import { createAction } from "redux-starter-kit"

type NotifyPayload = {
  message: string,
  variant: "success" | "warning" | "error" | "info",
}

export const notify: (payload: NotifyPayload) => Object = createAction(`NOTIFY`)
export const setUser = createAction(`SET_USER`)
export const setCheckoutId = createAction(`SET_CHECKOUT_ID`)
export const setCheckoutWebUrl = createAction(`SET_CHECKOUT_WEB_URL`)
export const setCustomerOrders = createAction(`SET_CUSTOMER_ORDERS`)
export const setSelectedVariant = createAction(`SET_SELECTED_VARIANT`)
export const setDeliveryAddress = createAction(`SET_DELIVERY_ADDRESS`)
export const setCollectionAddress = createAction(`SET_COLLECTION_ADDRESS`)
export const setBookAttributes = createAction(`SET_BOOK_ATTRIBUTES`)
export const setProjectId = createAction(`SET_PROJECT_ID`)
export const clearCheckout = createAction(`CLEAR_CHECKOUT`)
