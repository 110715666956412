module.exports = [{
      plugin: require('/codebuild/output/src931778540/src/bitbucket.org/QPhoto_Website/tell-a-story-web/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/codebuild/output/src931778540/src/bitbucket.org/QPhoto_Website/tell-a-story-web/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5P2K8DN","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}}},
    },{
      plugin: require('/codebuild/output/src931778540/src/bitbucket.org/QPhoto_Website/tell-a-story-web/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/codebuild/output/src931778540/src/bitbucket.org/QPhoto_Website/tell-a-story-web/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
