import PropTypes from "prop-types"
import React from "react"

import MuiTextField from "@material-ui/core/TextField"
import makeStyles from "@material-ui/core/styles/makeStyles"

const useStyles = makeStyles(theme => ({
  root: {
    margin: "0",
    marginBottom: "20px",
    "& .MuiFormLabel-root": {
      margin: "0",
      fontSize: "0.8rem",
      fontFamily: theme.font.galanoSemi,
      "&.Mui-focused": {
        color: theme.color.orange,
      },
    },
    "& .MuiInput-formControl": {
      fontSize: "0.8rem",
    },
    "& .MuiInput-underline": {
      "&:after": {
        borderColor: theme.color.orange,
      },
      "&:before": {
        borderBottom: `2px solid ${theme.color.grey}`,
      },
      "&.Mui-error": {
        "&:after": {
          borderColor: "red",
        },
      },
    },
    "& .MuiFormHelperText-root.Mui-error": {
      position: "absolute",
      bottom: "-15px",
      fontSize: "0.6rem",
    },
    "& .MuiFormLabel-asterisk": {
      display: "none",
    },
  },
}))

const TextField = ({ field, form, normalize = v => v, ...props }) => {
  const classes = useStyles()
  const error = form.errors[field.name] && form.touched[field.name]

  const handleBlur = () => {
    form.setFieldTouched(field.name, true)
  }

  const handleChange = event => {
    const value = normalize(event.target.value)
    form.setFieldValue(field.name, value)
  }

  return (
    <MuiTextField
      className={classes.root}
      error={error}
      fullWidth
      helperText={error ? form.errors[field.name] : ""}
      margin="normal"
      onBlur={handleBlur}
      onChange={handleChange}
      value={field.value}
      {...props}
    />
  )
}

TextField.propTypes = {
  ...MuiTextField.propTypes,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  normalize: PropTypes.func,
}

export default TextField
