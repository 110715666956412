import { combineReducers } from "redux"
import { createReducer } from "redux-starter-kit"

import {
  notify,
  setUser,
  setCheckoutId,
  setCheckoutWebUrl,
  setCustomerOrders,
  setSelectedVariant,
  setDeliveryAddress,
  setCollectionAddress,
  setBookAttributes,
  setProjectId,
  clearCheckout,
} from "./actions"

const notifications = createReducer(
  {
    notification: null,
  },
  {
    [notify]: (state, action) => {
      state.notification = action.payload
    },
  }
)

const auth = createReducer(
  {
    user: null,
  },
  {
    [setUser]: (state, action) => {
      const { payload = null } = action
      state.user = payload
    },
  }
)

const checkout = createReducer(
  {
    id: null,
    webUrl: null,
  },
  {
    [setCheckoutId]: (state, action) => {
      const { payload = null } = action
      state.id = payload
    },
    [setCheckoutWebUrl]: (state, action) => {
      const { payload = null } = action
      state.webUrl = payload
    },
    [clearCheckout]: state => {
      state.id = null
      state.webUrl = null
    },
  }
)

const shopify = createReducer(
  {
    orders: [],
    selectedVariant: null,
    deliveryAddress: null,
    collectionAddress: null,
    bookAttributes: null,
    projectId: null,
  },
  {
    [setCustomerOrders]: (state, action) => {
      const { payload = null } = action
      state.orders = payload
    },
    [setSelectedVariant]: (state, action) => {
      const { payload = null } = action
      state.selectedVariant = payload
    },
    [setDeliveryAddress]: (state, action) => {
      const { payload = null } = action
      state.deliveryAddress = payload
    },
    [setCollectionAddress]: (state, action) => {
      const { payload = null } = action
      state.collectionAddress = payload
    },
    [setBookAttributes]: (state, action) => {
      const { payload = null } = action
      state.bookAttributes = payload
    },
    [setProjectId]: (state, action) => {
      const { payload = null } = action
      state.projectId = payload
    },
    [clearCheckout]: state => {
      state.selectedVariant = null
      state.deliveryAddress = null
      state.collectionAddress = null
      state.bookAttributes = null
      state.projectId = null
    },
  }
)

const rootReducer = combineReducers({
  notifications,
  auth,
  checkout,
  shopify,
})

export default rootReducer
