const DPI = 96
const PT_TO_PX = 72
const INCH_IN_MM = 25.4

export const radiansToDegrees = radians => {
  return (radians * 180) / Math.PI
}

export const convertMMtoPX = value => {
  return (value * DPI) / INCH_IN_MM
}

export const convertPXtoMM = value => {
  return (value * INCH_IN_MM) / DPI
}

export const convertPXtoPT = value => {
  return (value * PT_TO_PX) / DPI
}

export const convertPTtoPX = value => {
  return (value * DPI) / PT_TO_PX
}

export const truncate = (text, length) => {
  if (!text || !length) return
  return text.length > length ? `${text.substring(0, length)}...` : text
}

export const floor = float => {
  if (!float) return
  return Math.floor(float)
}

export const scaleLongEdge = (originalWidth, originalHeight, maxLength) => {
  let scale
  if (originalWidth >= originalHeight) {
    scale = originalWidth / maxLength
  } else {
    scale = originalHeight / maxLength
  }
  return [originalWidth / scale, originalHeight / scale]
}
