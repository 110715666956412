import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

import { listFiles, uploadImage } from "../../utils/s3"
import { Button } from "../elements"
import GalleryImage from "./GalleryImage"
import styled from "styled-components"
import { Button as Btn } from "@material-ui/core"
import uploadImg from "../../images/application/upload-icon-grey.svg"
import CircularProgress from "@material-ui/core/CircularProgress"

import { useDispatch } from "react-redux"
import { notify } from "../../state/actions"

const Gallery = ({ imagePath, minPhotoCount, maxPhotoCount, onContinue }) => {
  const [isLoading, setLoading] = useState(true)
  const [isSubmitting, setSubmitting] = useState(false)
  const [images, setImages] = useState([])
  const [imagesUploading, setImagesUploading] = useState([])
  const [uploadProgresses, setUploadProgresses] = useState({})
  const [imagePreviews, setImagePreviews] = useState({})
  const [dropHover, setDropHover] = useState(false)
  const dispatch = useDispatch()

  const thumbnailPath = `${imagePath}/thumbs/`
  const rootPath = imagePath.endsWith("/") ? imagePath : `${imagePath}/`

  useEffect(() => {
    ;(async () => {
      try {
        setLoading(true)
        const files = await listFiles({ path: rootPath })
        const imagePathRegex = new RegExp(`${imagePath}\\/(.*)`)

        for (let file of files) {
          if (file.key.includes(thumbnailPath)) {
            continue
          }

          let match = file.key.match(imagePathRegex)
          if (match != null && match.length > 1) {
            const id = match[1]
            setImages(images => [...images, id])
          }
        }
      } finally {
        setLoading(false)
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imagePath])

  const upload = (file, id) => {
    let reader = new FileReader()

    reader.onload = async () => {
      setImagePreview(id, reader.result)
      setUploading(id, true)
      setUploadProgress(id, 0)
      setImages(images => [...images, id])

      await uploadImage({ id, path: rootPath, file }, progress => {
        setUploadProgress(id, (progress.loaded / progress.total) * 100)
      })

      // Give the lambda some time to create the thumbnail
      setTimeout(() => {
        setUploadProgress(id, 100)
        setUploading(id, false)
        setImagePreview(id, null)
      }, 2000)
    }
    reader.readAsDataURL(file)
  }

  const setUploading = (imageId, isUploading) => {
    setImagesUploading(imagesUploading => {
      if (isUploading) {
        return [...imagesUploading, imageId]
      } else if (imagesUploading.includes(imageId)) {
        imagesUploading.splice(imagesUploading.indexOf(imageId), 1)
        return [...imagesUploading]
      }
    })
  }

  const isImageUploading = imageId => {
    return imagesUploading.includes(imageId)
  }

  const anyImagesUploading = () => {
    return imagesUploading.length > 0
  }

  const getUploadProgress = imageId => {
    if (uploadProgresses[imageId]) {
      return uploadProgresses[imageId]
    }
    return 0
  }

  const setUploadProgress = (imageId, percent) => {
    setUploadProgresses(uploadProgresses => {
      return {
        ...uploadProgresses,
        [imageId]: percent,
      }
    })
  }

  const getImagePreview = imageId => {
    if (imagePreviews[imageId]) {
      return imagePreviews[imageId]
    }
    return null
  }

  const setImagePreview = (imageId, base64data) => {
    setImagePreviews(imagePreviews => {
      return {
        ...imagePreviews,
        [imageId]: base64data,
      }
    })
  }

  const handleImagesSelected = e => {
    const files = e.target.files
    for (let file of files) {
      const fileName = transformFileName(file.name)

      if (images.length < maxPhotoCount) {
        if (images.some(image => image == fileName)) {
          dispatch(
            notify({
              message: `Image ${file.name} has already been uploaded`,
              variant: "info",
            })
          )
        } else {
          upload(file, fileName)
        }
      }
    }
  }

  const handleImageDelete = imageId => {
    setImages(images => {
      images.splice(images.indexOf(imageId), 1)
      return [...images]
    })
  }

  const handleContinue = async e => {
    setSubmitting(true)
    await onContinue(e)
    setSubmitting(false)
  }

  const transformFileName = fileName => {
    const newFileName = encodeURI(
      fileName.replace(/[!@#$%^&*()=\s]/g, "").toLowerCase()
    )
    return newFileName
  }

  // DROPZONE
  const stopEvent = e => {
    e.preventDefault()
    e.stopPropagation()
  }
  const onDragOver = e => {
    stopEvent(e)
    setDropHover(true)
  }

  const onDragLeave = e => {
    stopEvent(e)
    setDropHover(false)
  }
  const onDrop = e => {
    stopEvent(e)
    const { files } = e.dataTransfer
    for (let file of files) {
      const fileName = transformFileName(file.name)

      if (images.length < maxPhotoCount) {
        if (images.some(image => image == fileName)) {
          dispatch(
            notify({
              message: `Image ${file.name} has already been uploaded`,
              variant: "info",
            })
          )
        } else {
          upload(file, fileName)
        }
      }
    }
    setDropHover(false)
  }

  const photoCount = images ? images.length : 0
  const uploadPercentage = (photoCount * 100) / maxPhotoCount
  return (
    <Container>
      {isLoading && (
        <div className="loading">
          <CircularProgress />
        </div>
      )}
      {!isLoading && (
        <>
          <DropZone
            onDrop={onDrop}
            onDragLeave={onDragLeave}
            onDragOver={onDragOver}
            className={dropHover ? "active" : null}
          >
            <input
              accept="image/*"
              style={{ display: "none" }}
              id="file-add"
              type="file"
              onChange={handleImagesSelected}
              multiple
              disabled={maxPhotoCount && photoCount >= maxPhotoCount}
            />
            <div className="drag-here">
              <img src={uploadImg} />
              <p>Drag and drop here</p>
            </div>
            <UploadButton variant="contained">
              <label htmlFor="file-add">Select</label>
            </UploadButton>
          </DropZone>
          {maxPhotoCount && (
            <Progress percentage={uploadPercentage}>
              <p>Upload progress&nbsp;</p>
              <p>
                {photoCount - imagesUploading.length}/{maxPhotoCount}
              </p>
            </Progress>
          )}

          {minPhotoCount && photoCount < minPhotoCount && (
            <p className="min-photos">
              Please upload at least {minPhotoCount} photos
            </p>
          )}

          <ImagesContainer>
            {images.map(imageId => {
              return (
                <GalleryImage
                  key={imageId}
                  path={rootPath}
                  thumbnailPath={thumbnailPath}
                  imageId={imageId}
                  uploading={isImageUploading(imageId)}
                  uploadProgress={getUploadProgress(imageId)}
                  preview={getImagePreview(imageId)}
                  handleDelete={handleImageDelete}
                  thumbnailWidth={100}
                  thumbnailHeight={100}
                />
              )
            })}
          </ImagesContainer>
          <Button
            onClick={handleContinue}
            className="upload margin-top"
            disabled={
              (minPhotoCount && photoCount < minPhotoCount) ||
              anyImagesUploading() ||
              isSubmitting ||
              images.length > maxPhotoCount
            }
            loading={isSubmitting}
          >
            NEXT
          </Button>
          {images.length > maxPhotoCount && (
            <MaxImgWarning>
              Sorry you have uploaded more than {maxPhotoCount} images
            </MaxImgWarning>
          )}
        </>
      )}
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  .loading {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .MuiCircularProgress-colorPrimary {
      color: ${props => props.theme.color.dark};
    }
  }
  .min-photos {
    font-size: 0.8rem;
    margin: 0 0 15px 0;
    color: ${props => props.theme.color.grey};
  }
  @media (min-width: 768px) {
    min-height: 35vh;
  }
`

const UploadButton = styled(Btn)`
  background: ${props => props.theme.color.orange};
  padding: 2px 25px;
  font-size: 0.8rem;
  font-family: ${props => props.theme.font.galanoSemi};
  color: ${props => props.theme.color.white};
  border: 2px solid ${props => props.theme.color.orange};
  box-shadow: none;
  transition: 0.25s ease;
  border-radius: 10px;
  label {
    cursor: pointer;
  }
  .MuiTouchRipple-ripple {
    color: ${props => props.theme.color.orange};
  }
  &:hover {
    background: ${props => props.theme.color.white};
    color: ${props => props.theme.color.dark};
  }
`

const DropZone = styled.div`
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px dashed ${props => props.theme.color.grey};
  margin: 20px 0;
  padding: 0 15px;
  transition: 0.25s ease;
  &.active {
    background: ${props => props.theme.color.light};
  }
  .drag-here {
    display: flex;
    align-items: center;
    width: 100%;
    p {
      font-size: 0.8rem;
      padding-left: 10px;
      font-family: ${props => props.theme.font.galanoSemi};
      color: ${props => props.theme.color.grey};
    }
    img {
      max-width: 40px;
    }
  }
`

const Progress = styled.div`
  width: 100%;
  height: 40px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  border-radius: 10px;
  background: ${props => props.theme.color.light};
  margin-bottom: 15px;
  &:after {
    content: "";
    position: absolute;
    border-radius: 10px;
    background: ${props => props.theme.color.orange};
    top: 0;
    bottom: 0;
    left: 0;
    width: ${props => props.percentage}%;
    max-width: 100%;
  }
  p {
    color: ${props => props.theme.color.grey};
    position: relative;
    z-index: 1;
    font-family: ${props => props.theme.font.galanoSemi};
    text-transform: uppercase;
    font-size: 0.8rem;
  }
`

const ImagesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`
Gallery.propTypes = {
  className: PropTypes.string,
  imagePath: PropTypes.string.isRequired,
  onContinue: PropTypes.func.isRequired,
  minPhotoCount: PropTypes.number,
  maxPhotoCount: PropTypes.number,
}

const MaxImgWarning = styled.p`
  color: red;
  margin: 20px 0;
  font-size: 0.8rem;
`
export default Gallery
