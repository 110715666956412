import React from "react"
import { ApolloProvider } from "react-apollo"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"

import { ApolloProvider as ApolloHooksProvider } from "@apollo/react-hooks"
import { StylesProvider, ThemeProvider } from "@material-ui/styles"
import { ThemeProvider as StyledThemeProvider } from "styled-components"

import { createApolloClient } from "./graphql"
import { createStore } from "./state"
import theme from "./theme"

// eslint-disable-next-line react/prop-types
const wrapRootElement = ({ element }) => {
  const { store, persistor } = createStore()
  const apolloClient = createApolloClient()
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ApolloProvider client={apolloClient}>
          <ApolloHooksProvider client={apolloClient}>
            <StylesProvider injectFirst>
              <ThemeProvider theme={theme}>
                <StyledThemeProvider theme={theme}>
                  {element}
                </StyledThemeProvider>
              </ThemeProvider>
            </StylesProvider>
          </ApolloHooksProvider>
        </ApolloProvider>
      </PersistGate>
    </Provider>
  )
}

export default wrapRootElement
