import "swiper/dist/css/swiper.min.css"
import "./src/styles/reset.css"
import "./src/styles/fonts.css"
import "./src/styles/theme.css"

import configureAmplify from "./src/configureAmplify"
import wrapPageElement from "./src/wrapPageElement"
import wrapRootElement from "./src/wrapRootElement"

configureAmplify()

export { wrapPageElement, wrapRootElement }
