import React from "react"

import Layout from "./components/Layout"

// eslint-disable-next-line react/prop-types
const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>
}

export default wrapPageElement
