import clsx from "clsx"
import PropTypes from "prop-types"
import React from "react"

import { IconButton, SnackbarContent } from "@material-ui/core"
import { amber, green } from "@material-ui/core/colors"
import { makeStyles } from "@material-ui/core/styles"
import {
  CheckCircle as CheckCircleIcon,
  Close as CloseIcon,
  Error as ErrorIcon,
  Info as InfoIcon,
  Warning as WarningIcon,
} from "@material-ui/icons"

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
}

const useStyles = makeStyles(theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
}))

const Notice = ({ className, message, onClose, variant, ...props }) => {
  const classes = useStyles()
  const Icon = variantIcon[variant]

  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      aria-describedby="client-notice"
      message={
        <span id="client-notice" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={
        onClose
          ? [
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={onClose}
              >
                <CloseIcon className={classes.icon} />
              </IconButton>,
            ]
          : null
      }
      {...props}
    />
  )
}

Notice.propTypes = {
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(["success", "warning", "error", "info"]).isRequired,
}

export default Notice
