import { createMuiTheme } from "@material-ui/core/styles"

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#5d99f8",
      contrastText: "#fff",
    },
    secondary: {
      main: "#ffa19f",
      contrastText: "#fff",
    },
    background: {
      default: "#ffffff",
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: [
      "galano_grotesqueregular",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  // CUSTOM THEME ITEMS
  section: {
    paddingOne: "0 5%",
    paddingTwo: "100px 5%",
  },
  color: {
    black: "#000000",
    white: "#ffffff",
    orange: "#e5bb6f",
    grey: "#6b6b6b",
    lightGrey: "#d7d7d7",
    dark: "#383838",
    light: "#eeeeee",
  },
  font: {
    galanoReg: "galano_grotesqueregular",
    galanoMed: "galano_grotesquemedium",
    galanoSemi: "galano_grotesquesemibold",
    galanoBold: "galano_grotesquebold",
    saturdayRock: "saturday-rock",
    butler: "butler",
  },
  breakpoint: {
    tablet: "@media (min-width:768px)",
    desktop: "@media (min-width:1024px)",
  },
})

export default theme
