/**
 * @flow
 */
import _ from "lodash"
import base64 from "base-64"
import { useEffect, useState, useCallback } from "react"
import Auth from "@aws-amplify/auth"
import Cache from "@aws-amplify/cache"
import { useMutation, useQuery } from "@apollo/react-hooks"
import {
  CUSTOMER_ACCESS_TOKEN_CREATE,
  CUSTOMER_ACCESS_TOKEN_RENEW,
  CUSTOMER_ACCESS_TOKEN_DELETE,
  CUSTOMER_RECOVER,
  CUSTOMER_RESET,
  CUSTOMER_CREATE,
} from "./mutations"
import { CUSTOMER_FETCH } from "./queries"

export const SHOPIFY_TOKEN_EXPIRED_ERROR = new Error(
  "Shopify token has expired, or does not exist"
)

const SHOPIFY_ERROR_CODES = {
  UNIDENTIFIED_CUSTOMER: "UNIDENTIFIED_CUSTOMER",
}

type shopifyCustomerAccessTokenType = {
  accessToken: string,
  expiresAt: string,
}

const customerIdToGlobalCustomerId = (customerId: string) => {
  const globalId = `gid://shopify/Customer/${customerId}`
  return base64.encode(globalId)
}

export const getAWSIdentity = async (shopifyAccessToken: string) => {
  const rawResponse = await fetch(
    // $FlowFixMe
    `${process.env.LIFESTORY_API_ENDPOINT}/auth/customer`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        app: "TELL_A_STORY",
        access_token: shopifyAccessToken,
      }),
    }
  )
  if (!rawResponse.ok) {
    throw new Error("Login failed, please try again later")
  }

  const response = await rawResponse.json()
  const { IdentityId, Token } = response

  return { IdentityId, Token }
}

export const doAWSSignIn = async (accessToken: string) => {
  const { IdentityId, Token } = await getAWSIdentity(accessToken)

  await Auth.federatedSignIn("developer", {
    token: Token,
    identity_id: IdentityId,
  })
}

export const cacheShopifyCustomerAccessToken = (
  shopifyCustomerAccessToken: shopifyCustomerAccessTokenType
) => {
  const expires = new Date(shopifyCustomerAccessToken.expiresAt).getTime()
  Cache.setItem(
    process.env.SHOPIFY_CUSTOMER_ACCESS_TOKEN_KEY,
    shopifyCustomerAccessToken.accessToken,
    {
      expires,
    }
  )
}

export const getCachedShopifyCustomerAccessToken = () => {
  return Cache.getItem(process.env.SHOPIFY_CUSTOMER_ACCESS_TOKEN_KEY)
}

export const useCustomerAccessToken = () => {
  const [customerAccessToken, setCustomerAccessToken] = useState(null)
  const [loading, setLoading] = useState(false)

  const getAccessToken = useCallback(async () => {
    setLoading(true)
    try {
      const customerAccessToken = await getCachedShopifyCustomerAccessToken()
      setCustomerAccessToken(customerAccessToken)
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    getAccessToken()
  }, [getAccessToken])

  return [customerAccessToken, loading]
}

export const useSignIn = () => {
  const [customerAccessTokenCreate] = useMutation(CUSTOMER_ACCESS_TOKEN_CREATE)

  const signIn = useCallback(
    async (email: string, password: string) => {
      const result = await customerAccessTokenCreate({
        variables: {
          input: {
            email,
            password,
          },
        },
      })
      const data = result.data.customerAccessTokenCreate
      if (!_.isEmpty(data.customerUserErrors)) {
        for (const error of data.customerUserErrors) {
          if (error.code === SHOPIFY_ERROR_CODES.UNIDENTIFIED_CUSTOMER) {
            throw new Error("Incorrect email or password.")
          } else {
            throw new Error("An error has occurred. Please try again later.")
          }
        }
      } else {
        cacheShopifyCustomerAccessToken(data.customerAccessToken)
        await doAWSSignIn(data.customerAccessToken.accessToken)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return signIn
}

export const useSignUp = () => {
  const [customerCreate] = useMutation(CUSTOMER_CREATE)
  const signIn = useSignIn()

  const create = useCallback(
    async (
      firstName: string,
      lastName: string,
      email: string,
      password: string,
      acceptsMarketing: boolean
    ) => {
      const errors = []
      let result

      try {
        result = await customerCreate({
          variables: {
            input: {
              firstName,
              lastName,
              email,
              password,
              acceptsMarketing,
            },
          },
        })

        if (result.data.customerCreate.customerUserErrors.length) {
          for (const error of result.data.customerCreate.customerUserErrors) {
            const { message } = error
            errors.push(message)
          }
        } else {
          if (await Auth.currentUserInfo()) {
            await Auth.signOut()
          }
          await signIn(email, password)
        }
      } catch (err) {
        console.error(err)
        throw err
      }
      return [result, errors]
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [signIn]
  )

  return create
}

export const useRecoverPassword = () => {
  const [customerRecover] = useMutation(CUSTOMER_RECOVER)

  const recover = useCallback(
    async (email: string) => {
      const result = await customerRecover({
        variables: {
          email,
        },
      })
      const data = result.data.customerRecover
      if (!_.isEmpty(data.customerUserErrors)) {
        for (const error of data.customerUserErrors) {
          if (error.code === SHOPIFY_ERROR_CODES.UNIDENTIFIED_CUSTOMER) {
            throw new Error("Customer does not exist.")
          } else {
            throw new Error("An error has occurred. Please try again later.")
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  return recover
}

export const useResetPassword = () => {
  const [customerReset] = useMutation(CUSTOMER_RESET)
  const signOut = useSignOut()

  const reset = useCallback(
    async (customerId: string, resetToken: string, password: string) => {
      const result = await customerReset({
        variables: {
          id: customerIdToGlobalCustomerId(customerId),
          input: {
            resetToken,
            password,
          },
        },
      })
      const data = result.data.customerReset
      if (!_.isEmpty(data.customerUserErrors)) {
        for (const error of data.customerUserErrors) {
          throw `Error: ${error.code}: ${error.message}`
        }
      } else {
        signOut()
        const { accessToken, expiresAt } = data.customerAccessToken
        cacheShopifyCustomerAccessToken({
          accessToken,
          expiresAt,
        })
        await doAWSSignIn(accessToken)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [signOut]
  )
  return reset
}

export const useRenewShopifyCustomerAccessToken = () => {
  const [customerAccessTokenRenew] = useMutation(CUSTOMER_ACCESS_TOKEN_RENEW)

  const renewToken = useCallback(async () => {
    const customerAccessToken = await getCachedShopifyCustomerAccessToken()

    if (!customerAccessToken) {
      throw SHOPIFY_TOKEN_EXPIRED_ERROR
    }

    const result = await customerAccessTokenRenew({
      variables: {
        customerAccessToken: customerAccessToken,
      },
    })

    const data = result.data.customerAccessTokenRenew
    if (!_.isEmpty(data.userErrors)) {
      throw data.userErrors[0].message
    }

    cacheShopifyCustomerAccessToken(data.customerAccessToken)
    return data.customerAccessToken
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return renewToken
}

export const useSignOut = () => {
  const [customerAccessTokenDelete] = useMutation(CUSTOMER_ACCESS_TOKEN_DELETE)

  const signOut = useCallback(async () => {
    const customerAccessToken = await getCachedShopifyCustomerAccessToken()

    if (customerAccessToken) {
      try {
        await customerAccessTokenDelete({
          variables: {
            customerAccessToken,
          },
        })
        Cache.removeItem(process.env.SHOPIFY_CUSTOMER_ACCESS_TOKEN_KEY)
        //eslint-disable-next-line
      } catch {}
    }
    await Auth.signOut()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return signOut
}

export const useCustomerDetails = () => {
  const [accessToken, accessTokenLoading] = useCustomerAccessToken()
  const queryResult = useQuery(CUSTOMER_FETCH, {
    variables: {
      customerAccessToken: accessToken,
    },
    skip: accessTokenLoading || _.isNull(accessToken),
  })

  return [
    _.get(queryResult, "data.customer"),
    accessTokenLoading || _.get(queryResult, "loading"),
    _.get(queryResult, "error"),
  ]
}

export const refreshToken = () => {
  return new Promise<Object>(async resolve => {
    try {
      const shopifyToken = await getCachedShopifyCustomerAccessToken()
      const { IdentityId: identity_id, Token: token } = await getAWSIdentity(
        shopifyToken
      )
      resolve({ token, identity_id })
    } catch (err) {
      console.error(err)
    }
  })
}
