import clsx from "clsx"
import { Form as FormikForm } from "formik"
import PropTypes from "prop-types"
import React from "react"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles({
  form: {
    width: "100%", // Fix IE 11 issue.
  },
})

const Form = ({ className, ...props }) => {
  const classes = useStyles()
  return <FormikForm className={clsx(classes.form, className)} {...props} />
}

Form.propTypes = {
  className: PropTypes.string,
}

export default Form
