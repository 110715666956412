import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from "apollo-boost"
import fetch from "isomorphic-unfetch"

let apolloClient = null

// Polyfill fetch() on the server (used by apollo-client)
if (!process.browser) {
  global.fetch = fetch
}

const lifeStoryLink = new HttpLink({
  uri: `${process.env.LIFESTORY_GRAPHQL_ENDPOINT}`,
  fetch: async (uri, options) => {
    // TODO: Set AWS auth headers
    // options.headers["Authorization"] = ???
    return fetch(uri, options)
  },
})

const shopifyLink = new HttpLink({
  uri: `${process.env.SHOPIFY_GRAPHQL_ENDPOINT}`,
  fetch: async (uri, options) => {
    options.headers["X-Shopify-Storefront-Access-Token"] =
      process.env.SHOPIFY_ACCESS_TOKEN
    return fetch(uri, options)
  },
})

const create = (initialState = {}) => {
  return new ApolloClient({
    connectToDevTools: process.browser,
    ssrMode: !process.browser, // disable forceFetch on the server (so queries are only run once)
    link: ApolloLink.split(
      operation => operation.getContext().clientName === "lifestory",
      lifeStoryLink,
      shopifyLink
    ),
    cache: new InMemoryCache().restore(initialState),
  })
}

const createApolloClient = initialState => {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (!process.browser) {
    return create(initialState)
  }

  // Reuse client on the client-side
  if (!apolloClient) {
    apolloClient = create(initialState)
  }

  return apolloClient
}

export default createApolloClient
