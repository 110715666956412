/**
 * @flow
 */

import React from "react"

import { makeStyles } from "@material-ui/core/styles"

import logo from "./logo.png"

export const useStyles = makeStyles({
  logo: props => ({
    maxWidth: props.size,
    maxHeight: props.size,
  }),
})

type Props = {
  size: number | string,
}

const Logo = (props: Props) => {
  const classes = useStyles(props)
  return <img src={logo} className={classes.logo} />
}

export default Logo
