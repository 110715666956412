import PropTypes from "prop-types"
import React from "react"

import {
  IconButton,
  DialogTitle as MuiDialogTitle,
  Typography,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import CloseIcon from "@material-ui/icons/Close"

const useStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}))

const DialogTitle = ({ children, onClose }) => {
  const classes = useStyles()
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
}

DialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func,
}

export default DialogTitle
