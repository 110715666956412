import clsx from "clsx"
import { Link as GatsbyLink } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  link: {
    ...theme.typography.body2,
  },
}))

const Link = ({ className, ...props }) => {
  const classes = useStyles()
  return <GatsbyLink className={clsx(classes.link, className)} {...props} />
}

Link.propTypes = {
  className: PropTypes.string,
}

export default Link
