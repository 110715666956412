import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import { Delete as DeleteIcon } from "@material-ui/icons"
import {
  CircularProgress,
  Tooltip,
  IconButton,
  Card,
  CardContent,
  CardActions,
  Typography,
} from "@material-ui/core"

import { makeStyles } from "@material-ui/core/styles"

import { truncate } from "../../utils"
import { constructKey, getFile, deleteImage } from "../../utils/s3"

const useStyles = makeStyles({
  card: {
    margin: "0.3em",
    display: "inline-block",
    "& .MuiCardActions-root": {
      justifyContent: "space-between",
    },
  },
  cardContent: {
    position: "relative",
    textAlign: "center",
    padding: "0.5em",
  },
  deleteButton: {
    padding: 0,
  },
  fileName: {
    fontSize: "0.6em",
  },
})

const ContentOverlay = styled.div`
  background-color: ${props =>
    props.isLoading ? "rgba(255, 255, 255, 0.8)" : "transparent"};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const LoaderContainer = styled.div`
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  left: 0;
  right: 0;
  text-align: center;
  display: ${props => (props.isLoading ? "block" : "none")};
`

const ImageContainer = styled.div`
  text-align: center;
  margin: 0 auto;
`

const GalleryImage = props => {
  const {
    path,
    thumbnailPath,
    imageId,
    uploading,
    uploadProgress,
    handleDelete,
    preview,
    thumbnailWidth = 400,
    thumbnailHeight = 400,
  } = props

  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [source, setSource] = useState(null)

  useEffect(() => {
    ;(async () => {
      if (!uploading) {
        setIsLoading(true)

        let data
        try {
          // Try to load the thumbnail first
          const thumbsPath = `${thumbnailPath}${thumbnailWidth}x${thumbnailHeight}/`
          data = await getFile({
            key: constructKey(thumbsPath, imageId),
            download: true,
          })
        } catch (err) {
          console.warn("Couldn't find thumbnail, loading full size image.")
        }

        try {
          if (!data) {
            // Load the full image
            data = await getFile({
              key: constructKey(path, imageId),
              download: true,
            })
          }

          const blob = new Blob([data.Body], { type: data.ContentType })
          const reader = new FileReader()
          reader.onload = () => {
            setSource(reader.result)
          }
          reader.readAsDataURL(blob)
        } catch (err) {
          console.error("Failed to get image, removing from list")
          handleDelete(imageId)
        } finally {
          setIsLoading(false)
        }
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageId, uploading])

  const onDelete = async () => {
    await deleteImage({ imageId, path })
    handleDelete(imageId)
  }

  return (
    <Card className={classes.card}>
      <CardContent
        className={classes.cardContent}
        style={{
          minWidth: `${thumbnailWidth}px`,
          minHeight: `${thumbnailHeight}px`,
        }}
      >
        <ContentOverlay isLoading={uploading || isLoading}>
          <LoaderContainer isLoading={uploading || isLoading}>
            <CircularProgress
              variant={uploading ? "determinate" : "indeterminate"}
              value={uploadProgress}
              color="primary"
            />
          </LoaderContainer>
        </ContentOverlay>
        <ImageContainer
          style={{
            width: thumbnailWidth + "px",
            height: thumbnailHeight + "px",
          }}
        >
          <img
            src={source || preview}
            style={{
              maxWidth: thumbnailWidth + "px",
              maxHeight: thumbnailHeight + "px",
            }}
          />
        </ImageContainer>
      </CardContent>

      {!uploading && !isLoading && (
        <CardActions>
          <Typography
            className={classes.fileName}
            variant="caption"
            gutterBottom
          >
            {truncate(imageId, 10)}
          </Typography>

          <Tooltip title="Delete Photo">
            <IconButton
              className={classes.deleteButton}
              aria-label="Delete photo"
              component="span"
              onClick={onDelete}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </CardActions>
      )}
    </Card>
  )
}

GalleryImage.propTypes = {
  path: PropTypes.string.isRequired,
  thumbnailPath: PropTypes.string.isRequired,
  imageId: PropTypes.string.isRequired,
  uploading: PropTypes.bool,
  uploadProgress: PropTypes.number,
  handleDelete: PropTypes.func.isRequired,
  preview: PropTypes.string,
  thumbnailWidth: PropTypes.number,
  thumbnailHeight: PropTypes.number,
}

export default GalleryImage
