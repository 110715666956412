import PropTypes from "prop-types"
import React from "react"

import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import Input from "@material-ui/core/Input"
import makeStyles from "@material-ui/core/styles/makeStyles"

const useStyles = makeStyles(theme => ({
  root: {
    "&.MuiInputBase-root": {
      margin: "15px 0",
      fontSize: "0.8rem",
      fontFamily: theme.font.galanoSemi,
      color: theme.color.grey,
      "&.Mui-error": {
        "& .MuiSelect-root": {
          color: "red",
        },
      },
      "&.Mui-focused": {
        color: theme.color.orange,
        "&:after": {
          borderColor: theme.color.orange,
        },
      },
      "&:before": {
        borderBottom: `2px solid ${theme.color.grey}`,
      },
    },
    "& .MuiFormLabel-asterisk": {
      display: "none",
    },
  },
}))

const SelectField = ({
  field,
  form,
  normalize = v => v,
  options,
  includeEmptyOption,
  labelAsPlaceholder = true,
  label,
  ...props
}) => {
  const error = form.errors[field.name] && form.touched[field.name]
  const classes = useStyles()

  const handleBlur = () => {
    form.setFieldTouched(field.name, true)
  }

  const handleChange = event => {
    const value = normalize(event.target.value)
    form.setFieldValue(field.name, value)
  }

  return (
    <>
      <Select
        className={classes.root}
        value={field.value}
        onChange={handleChange}
        onBlur={handleBlur}
        error={error}
        displayEmpty
        input={<Input name={field.name} fullWidth />}
        {...props}
      >
        {includeEmptyOption && <MenuItem value="">None</MenuItem>}
        {labelAsPlaceholder && (
          <MenuItem value="" disabled>
            {label}
          </MenuItem>
        )}
        {options.map((item, index) => (
          <MenuItem key={`option_${index}`} value={item.value}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </>
  )
}

SelectField.propTypes = {
  ...Select.propTypes,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  normalize: PropTypes.func,
  options: PropTypes.array.isRequired,
  includeEmptyOption: PropTypes.bool,
  labelAsPlaceholder: PropTypes.bool,
}

export default SelectField
