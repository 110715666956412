import React from "react"
import Button from "./Button"
import makeStyles from "@material-ui/core/styles/makeStyles"

const useStyles = makeStyles(theme => ({
  button: {
    "& .MuiButtonBase-root": {
      border: `2px solid ${theme.color.orange}`,
      fontSize: "1rem",
      color: theme.color.white,
      padding: "4px",
      borderRadius: "10px",
      background: theme.color.orange,
      fontFamily: theme.font.galanoSemi,
      transition: "all 0.25s ease",
      boxShadow: "none",
      "&.submit": {
        "&.personal-details": {
          marginTop: "50px",
        },
        "&.contact": {
          marginTop: "20px",
        },
      },
      "&.sign-in": {
        margin: "20px 0",
      },
      "&.Mui-disabled": {
        opacity: "0.5",
      },
      "&.product": {
        marginTop: "10px",
      },
      "&:hover": {
        color: theme.color.black,
        background: theme.color.white,
      },
      "& .MuiTouchRipple-ripple": {
        color: theme.color.orange,
      },
    },
    [theme.breakpoint.tablet]: {
      "& .MuiButtonBase-root": {
        "&.submit": {
          maxWidth: "150px",
          "&.contact": {
            float: "right",
          },
        },
      },
    },
    [theme.breakpoint.desktop]: {
      "& .MuiButtonBase-root": {
        "&.product": {
          maxWidth: "330px",
        },
      },
    },
  },
}))

const SubmitButton = props => {
  const classes = useStyles()
  return <Button type="submit" rootClassName={classes.button} {...props} />
}

export default SubmitButton
