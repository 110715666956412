import Storage from "@aws-amplify/storage"

export const uploadImage = async (payload, progressCallback) => {
  const path = payload.path || ""
  const level = payload.level || "private"

  try {
    const key = constructKey(path, payload.id)
    await Storage.put(key, payload.file, {
      level: level,
      contentType: payload.file.type,
      metadata: {
        filename: encodeURI(payload.file.name),
      },
      progressCallback,
    })
  } catch (err) {
    console.error(`Error uploading image to S3: ${err}`)
    throw err
  }
}

export const deleteImage = async ({ imageId, path, level }) => {
  path = path || ""
  level = level || "private"
  const key = constructKey(path, imageId)

  try {
    await Storage.remove(key, { level: level })
  } catch (err) {
    console.error(`Error deleting image ${key} from S3: ${err}`)
    throw err
  }
}

export const listFiles = async ({ path, level }) => {
  path = path || ""
  level = level || "private"

  let keys = []
  try {
    keys = await Storage.list(path, { level: level })
  } catch (err) {
    console.error(`Error listing files in ${path}: ${err}`)
  }
  return keys
}

export const getFile = async ({ key, level, download }) => {
  if (!key) return null
  level = level || "private"
  download = download || false

  let object
  try {
    object = await Storage.get(key, { level, download })
  } catch (err) {
    console.error(`Error getting file ${key}: ${err}`)
  }
  return object
}

export const constructKey = (path, id) => {
  return `${path}${id}`
}
