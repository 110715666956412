import { configureStore } from "redux-starter-kit"
import { persistStore, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"

import rootReducer from "./reducers"

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["auth", "notifications"],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const createStore = preloadedState => {
  const store = configureStore({
    reducer: persistedReducer,
    preloadedState,
  })
  const persistor = persistStore(store)

  if (process.env.NODE_ENV !== "production" && module.hot) {
    module.hot.accept("./reducers", () =>
      store.replaceReducer(persistedReducer)
    )
  }

  return { store, persistor }
}

export default createStore
