import React from "react"

import { Box, CircularProgress } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
})

const FullScreenProgress = props => {
  const classes = useStyles()
  return (
    <Box className={classes.root}>
      <CircularProgress {...props} />
    </Box>
  )
}

export default FullScreenProgress
