// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/codebuild/output/src931778540/src/bitbucket.org/QPhoto_Website/tell-a-story-web/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/codebuild/output/src931778540/src/bitbucket.org/QPhoto_Website/tell-a-story-web/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/codebuild/output/src931778540/src/bitbucket.org/QPhoto_Website/tell-a-story-web/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-account-js": () => import("/codebuild/output/src931778540/src/bitbucket.org/QPhoto_Website/tell-a-story-web/src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-app-js": () => import("/codebuild/output/src931778540/src/bitbucket.org/QPhoto_Website/tell-a-story-web/src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-contact-js": () => import("/codebuild/output/src931778540/src/bitbucket.org/QPhoto_Website/tell-a-story-web/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-examples-js": () => import("/codebuild/output/src931778540/src/bitbucket.org/QPhoto_Website/tell-a-story-web/src/pages/examples.js" /* webpackChunkName: "component---src-pages-examples-js" */),
  "component---src-pages-faq-js": () => import("/codebuild/output/src931778540/src/bitbucket.org/QPhoto_Website/tell-a-story-web/src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-forgot-password-js": () => import("/codebuild/output/src931778540/src/bitbucket.org/QPhoto_Website/tell-a-story-web/src/pages/forgot-password.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-index-js": () => import("/codebuild/output/src931778540/src/bitbucket.org/QPhoto_Website/tell-a-story-web/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("/codebuild/output/src931778540/src/bitbucket.org/QPhoto_Website/tell-a-story-web/src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-products-js": () => import("/codebuild/output/src931778540/src/bitbucket.org/QPhoto_Website/tell-a-story-web/src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-reset-password-js": () => import("/codebuild/output/src931778540/src/bitbucket.org/QPhoto_Website/tell-a-story-web/src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-sign-in-js": () => import("/codebuild/output/src931778540/src/bitbucket.org/QPhoto_Website/tell-a-story-web/src/pages/sign-in.js" /* webpackChunkName: "component---src-pages-sign-in-js" */),
  "component---src-pages-sign-up-js": () => import("/codebuild/output/src931778540/src/bitbucket.org/QPhoto_Website/tell-a-story-web/src/pages/sign-up.js" /* webpackChunkName: "component---src-pages-sign-up-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("/codebuild/output/src931778540/src/bitbucket.org/QPhoto_Website/tell-a-story-web/src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-things-to-do-while-you-wait-js": () => import("/codebuild/output/src931778540/src/bitbucket.org/QPhoto_Website/tell-a-story-web/src/pages/things-to-do-while-you-wait.js" /* webpackChunkName: "component---src-pages-things-to-do-while-you-wait-js" */),
  "component---src-pages-user-journey-js": () => import("/codebuild/output/src931778540/src/bitbucket.org/QPhoto_Website/tell-a-story-web/src/pages/user-journey.js" /* webpackChunkName: "component---src-pages-user-journey-js" */)
}

